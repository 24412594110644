import { BaseService } from "../../BaseService";
import { CatalogPricingRequestDTO } from "@/models/catalog/catalog-pricing/CatalogPricingStoreRequest";
import ConfigService from "../../ConfigService";

class CatalogPricingService extends BaseService {
  async store(dataRequest: CatalogPricingRequestDTO) {
    const route = `/api/v1/catalog/pricing`;
    const response = await this.post(route, dataRequest);
    return response.data;
  }

  async getCatalogPricing(groupOrVersion: string, fipecode: string) {
    let route;
    const isPdmPricing = ConfigService.isPdmPricingEnabled();
    if (isPdmPricing) {
      route = `/api/v1/catalog/pricing?version=${groupOrVersion}&fipecode=${fipecode}`;
    } else {
      route = `/api/v1/catalog/pricing?group=${groupOrVersion}&fipecode=${fipecode}`;
    }
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }
}

export default new CatalogPricingService("", "");
