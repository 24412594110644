export default class ConfigService {
  /**
   * Retrieve a configuration value by key.
   *
   * @param key The key of the configuration.
   * @returns The configuration value.
   */
  static getConfigValue(key: string): boolean {
    const siteConfigsString = localStorage.getItem("siteConfigs");
    if (!siteConfigsString) {
      return false; // Retorna false se `siteConfigs` não existir
    }
    const siteConfigs = JSON.parse(siteConfigsString);
    const value = siteConfigs?.[key]?.valor || "0";
    return value === "1"; // Converte "1" para true e qualquer outro valor para false
  }
  /**
   * Check if the CAR_SUBSCRIPTION_ALLOW_PDM_PRICING flag is enabled.
   *
   * @returns True if the flag is enabled, otherwise false.
   */
  static isPdmPricingEnabled(): boolean {
    return !!this.getConfigValue("CAR_SUBSCRIPTION_ALLOW_PDM_PRICING");
  }
}
