import { BaseService } from "../BaseService";
import { BranchStoreRequest } from "@/models/branch/BranchStoreRequest";
import { ICatalogServiceConfig, IStoreCatalogItem } from "@/models/catalog/Catalog";
import ConfigService from "../ConfigService";

class CatalogService extends BaseService {
  async getAllCatalogs($channelId: number | null = null, page = 1, needle: string | null = null, sortColumn: string | null = "name") {
    let route;
    const isPdmPricing = ConfigService.isPdmPricingEnabled();
    if (isPdmPricing) {
      route = `/api/v1/version?&page=${page}&order=ASC&column=${sortColumn}`;
    } else {
      route = `/api/v1/group?CanalID=${$channelId}&page=${page}&order=ASC&column=${sortColumn}`;
    }
    if (needle) {
      route = route + `&search=${needle}`;
    }
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async getAllCatalogWithoutPaginate() {
    let route;
    const isPdmPricing = ConfigService.isPdmPricingEnabled();
    if (isPdmPricing) {
      route = `/api/v1/version?without_paginate=true`;
    } else {
      route = `/api/v1/group?without_paginate=true`;
    }
    const response = await this.get(route);
    return response.data;
  }

  async getCatalog($id: string) {
    let route;
    const isPdmPricing = ConfigService.isPdmPricingEnabled();
    if (isPdmPricing) {
      route = `/api/v1/version?PdmVersionID=${$id}`;
    } else {
      route = `/api/v1/group?GrupoID=${$id}`;
    }
    const response = await this.get(route);
    return response.data;
  }

  async getAllBranch($module: any | null = "", $group: any | null = "", page = 1, needle: string | null = null, orderColumn: string | null = "", sortedBy: string | null = "asc", showCases = false, RegiaoID = "") {
    let route = `/api/v1/branch?module=${$module}&branch_type=${$group}&page=${page}&orderBy=${orderColumn}&sortedBy=${sortedBy}&showCases=${showCases}&RegiaoID=${RegiaoID}`;
    if (needle) {
      route = route + `&search=${needle}`;
    }
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async getAllBranchByActive($module: any | null = "", $group: any | null = "", needle: string | null = null, isActive = true) {
    let route = `/api/v1/branch-show-cases?module=${$module}&branch_type=${$group}&active=${isActive}`;
    if (needle) {
      route = route + `&search=${needle}`;
    }
    const response = await this.instance.get(route, this.getConfigHeaders());

    return response.data;
  }

  async showCaseByLocationAndGroup(filialId: any | null = "", $group: any | null = "", page = 1, needle: string | null = null, sortColumn: string | null = "name") {
    const route = `/api/v1/show-case-by-location-and-group?filialId=${filialId}&branch_type=${$group}`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async showCaseByLocationAndVersion(filialId: any | null = "", $version: any | null = "") {
    const route = `/api/v1/show-case-by-location-and-version?filialId=${filialId}&pdmVersaoId=${$version}`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async showCaseByFipeCodeAndGroup(fipecode: any | null = "", $group: any | null = "") {
    const route = `/api/v1/show-case-by-fipe-and-group/${fipecode}/${$group}`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async showCaseByFipeCodeAndVersion(fipecode: any | null = "", $version: any | null = "") {
    const route = `/api/v1/show-case-by-fipe-and-version/${fipecode}/${$version}`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async getRegions($module: any | null = "", $group: any | null = "") {
    // Pega todas as regiões
    // const route = `/api/v1/region`;
    const route = `/api/v1/branch-regions?module=${$module}&branch_type=${$group}`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  /**
   * store
   * @param dataRequest
   */
  async store(dataRequest: BranchStoreRequest) {
    const route = `/api/v1/showCases`;
    const response = await this.post(route, dataRequest);
    return response.data;
  }

  /**
   * @param config
   */
  async getAllCatalog(config?: ICatalogServiceConfig) {
    const getConfigHeaders = (): object => {
      const headers: any = {
        Authorization: "Bearer " + localStorage.getItem("BEARER_TOKEN"),
        Accept: "application/json",
      };
      if (config?.channel !== undefined) {
        headers.channel = config.channel;
      }
      if (config?.subchannel !== undefined) {
        headers.subchannel = config.subchannel;
      }
      return { headers };
    };
    const route = `/api/v1/catalog`;
    const response = await this.instance.get(route, getConfigHeaders());
    return response.data;
  }

  /**
   * @param codeFrom
   * @param catalogGroup
   */
  async getCatalogMap(codeFrom: string | null = null, catalogGroup: string | null = null) {
    let route = "/api/v1/catalog-map?";
    const params: { [key: string]: string | null } = {
      codeFrom,
      catalogGroup,
    };
    const queryString = Object.keys(params)
      .filter((key) => params[key] !== null)
      .map((key) => `${key}=${encodeURIComponent(params[key] as string)}`)
      .join("&");
    route += queryString;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async storeCatalogMap(dataRequest: IStoreCatalogItem[]) {
    const route = "/api/v1/catalog-map";
    const response = await this.post(route, dataRequest);
    return response.data;
  }

  async storeAll(dataRequest: BranchStoreRequest) {
    const route = `/api/v1/catalog/storeAll`;
    const response = await this.post(route, dataRequest);
    return response.data;
  }

  async getDeliveryTime() {
    const route = `/api/v1/delivery-time`;
    const response = await this.get(route);
    return response.data;
  }

  async clearCatalogSync() {
    const route = `api/v1/catalog/clear-catalog-sync`;
    const response = await this.delete(route);
    return response.data;
  }
  // /**
  //  * CatalogTags
  //  * @param dataRequest
  //  */
  // async catalogTagStore(dataRequest: CatalogTagStoreRequest) {
  //   const route = `/api/v1/catalogTag`;
  //   const response = await this.post(route, dataRequest);
  //   return response.data;
  // }

  // async getCatalogTags() {
  //   const route = `/api/v1/catalogTags`;
  //   const response = await this.instance.get(route, this.getConfigHeaders());
  //   return response.data;
  // }
}

export default new CatalogService("", "");
